/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Mukta:wght@700&family=Open+Sans:wght@300&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;



html,
body,
#root,
.app {
  /* background-color: #010026; */
  @apply text-black;
}

@layer base {

  h1 {
    @apply text-4xl font-bold;
    font-family: 'Playfair Display', serif;
    font-size: 2em;
    
  }
  h2 {
    @apply text-2xl font-bold;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
  }
  h3 {
    @apply text-2xl font-semibold;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
  }
  h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.0em;
    font-weight: 200
  }

  li {
    @apply p-2
  }

  a {
    @apply p-2
  }

}

